import React from 'react';
import Card from 'components/card';
import Spinner from 'components/spinner';
import './stylesheets/index.scss';

const namespace = 'loading-card';

const LoadingCard = () => {
  return (
    <Card>
      <div className={`${namespace}`}>
        <Spinner className={`${namespace}__spinner`} mode="dark" />
        <h2 className={`${namespace}__text`}>Loading&hellip;</h2>
      </div>
    </Card>
  );
};

export default LoadingCard;
