import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/card';
import InfoCardTotal from './total';
import GratuityLine from './gratuity-line';
import './stylesheets/index.scss';

const namespace = 'info-card';

const InfoCard = ({
  address,
  logoUrl,
  showGratuityLine,
  storeName,
  subtotal,
  tax,
  total,
}) => {
  return (
    <Card className={`${namespace}`}>
      {(address || logoUrl || storeName) && (
        <div className={`${namespace}__store-info-container`}>
          {logoUrl && (
            <div className={`${namespace}__logo-container`}>
              <img
                className={`${namespace}__logo`}
                src={logoUrl}
                alt={storeName || 'Store Logo'}
              />
            </div>
          )}
          {storeName && (
            <h2 className={`${namespace}__store-name`}>{storeName}</h2>
          )}
          {address && (
            <address className={`${namespace}__address`}>{address}</address>
          )}
        </div>
      )}
      <div className={`${namespace}__totals-container`}>
        {subtotal > 0 && (
          <InfoCardTotal
            className={`${namespace}__subtotal`}
            label="Subtotal"
            value={subtotal}
          />
        )}
        {tax > 0 && (
          <InfoCardTotal
            className={`${namespace}__tax`}
            label="Sales Tax"
            value={tax}
          />
        )}
        {showGratuityLine && <GratuityLine />}
        <InfoCardTotal
          className={`${namespace}__total`}
          label="Total"
          value={total}
          mode="standalone"
        />
      </div>
    </Card>
  );
};

InfoCard.propTypes = {
  address: PropTypes.string,
  logoUrl: PropTypes.string,
  showGratuityLine: PropTypes.bool.isRequired,
  storeName: PropTypes.string,
  subtotal: PropTypes.number.isRequired,
  tax: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default InfoCard;
