import isNumber from 'lodash/isNumber';
import Decimal from 'decimal.js-light';

export const precisionRounding = ({ value, decimalPlaces }) => {
  if (!isNumber(value) || !isNumber(decimalPlaces)) return NaN;
  return new Decimal(value).toDecimalPlaces(decimalPlaces).toNumber();
};

export const precisionPercentageOf = ({ value, percentage, decimalPlaces }) => {
  if (!isNumber(value) || !isNumber(percentage) || !isNumber(decimalPlaces))
    return NaN;

  return precisionRounding({
    value: new Decimal(value).dividedBy(100).times(percentage).toNumber(),
    decimalPlaces,
  });
};
