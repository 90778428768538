import Immutable from 'immutable';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import validators from './validators';

class FormValidation {
  constructor({ ruleset } = {}) {
    this.ruleset = ruleset;
  }

  validateField = (fieldKey, fieldValue) => {
    const fieldRules = this.ruleset.get(fieldKey);

    let returnValue = true;
    for (const rule of fieldRules) {
      const errorMessage = rule.get('message');
      const validator = rule.get('validator');
      const options = rule.get('validatorOptions', Immutable.Map());

      const validatorMethod = isFunction(validator)
        ? validator
        : validators[validator];

      const validatorResponse = validatorMethod(fieldValue, options);

      if (validatorResponse !== true) {
        returnValue = isString(validatorResponse)
          ? validatorResponse
          : errorMessage;
        break;
      }
    }

    return returnValue;
  };
}

export default FormValidation;
