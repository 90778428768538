import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import './stylesheets/index.scss';

const namespace = 'spinner';

const Spinner = ({ animate = true, className, mode }) => {
  const containerClasses = cx({
    [namespace]: true,
    [`${namespace}--${mode}`]: true,
    [`${namespace}--animate`]: animate,
    [className]: !isEmpty(className),
  });

  return (
    <div className={containerClasses}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
        <path
          fill="currentColor"
          fillOpacity=".3"
          d="M16.687 16.687A8.75 8.75 0 0 1 1.75 10.5a8.75 8.75 0 0 1 8.75-8.75V0C4.7 0 0 4.7 0 10.5 0 16.3 4.7 21 10.5 21a10.47 10.47 0 0 0 7.425-3.075l-1.238-1.238z"
        />
        <path
          fill="currentColor"
          d="M10.5 0v1.75a8.75 8.75 0 0 1 6.187 14.937l1.237 1.237C19.824 16.024 21 13.4 21 10.5 21 4.701 16.299 0 10.5 0"
        />
      </svg>
    </div>
  );
};

Spinner.propTypes = {
  className: PropTypes.string,
  animate: PropTypes.bool,
  mode: PropTypes.oneOf(['light', 'dark']).isRequired,
};

export default Spinner;
