import { connect } from 'react-redux';
import Component from '../';
import {
  arePaymentRequestUpdatesBlockedSelector,
  fieldErrorSelector,
  hasFieldErroredSelector,
  isPaymentFormIncompleteSelector,
  isPaymentFormProcessingSelector,
  paymentFormFieldValueSelector,
  paymentFormProcessingErrorMessageSelector,
} from 'selectors';
import confirmCardPayment from 'api/confirm-card-payment';
import {
  paymentFormFieldBlurred,
  paymentFormSetFieldValue,
  paymentFormSetStripeField,
  paymentFormResetProcessing,
} from 'reducers/payment-form';

const mapStateToProps = (state) => ({
  arePaymentRequestUpdatesBlocked: arePaymentRequestUpdatesBlockedSelector(
    state
  ),
  billingZipCodeValue: paymentFormFieldValueSelector(state, 'billingZipCode'),
  cardholderNameErrors: fieldErrorSelector(state, 'cardholderName'),
  cardholderNameValue: paymentFormFieldValueSelector(state, 'cardholderName'),
  cardNumberAndCvvErrors: fieldErrorSelector(state, 'cardNumber', 'cvv'),
  expiryAndBillingZipCodeErrors: fieldErrorSelector(
    state,
    'expiry',
    'billingZipCode'
  ),
  hasBillingZipCodeErrored: hasFieldErroredSelector(state, 'billingZipCode'),
  hasCardholderNameErrored: hasFieldErroredSelector(state, 'cardholderName'),
  isPaymentFormIncomplete: isPaymentFormIncompleteSelector(state),
  isPaymentFormProcessing: isPaymentFormProcessingSelector(state),
  processingErrorMessage: paymentFormProcessingErrorMessageSelector(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFieldBlur(id) {
    dispatch(paymentFormFieldBlurred(id));
  },
  onFieldChange({ id, value }) {
    dispatch(paymentFormSetFieldValue({ id, value }));
  },
  onStripeChange({ id, error, complete }) {
    dispatch(paymentFormSetStripeField({ id, error, complete }));
  },
  onPaymentButtonClick({ stripe, elements }) {
    window.scrollTo(0, 0);
    dispatch(confirmCardPayment({ stripe, elements }));
  },
  resetProcessing() {
    dispatch(paymentFormResetProcessing());
  },
});

const PaymentCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default PaymentCardContainer;
