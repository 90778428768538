import { connect } from 'react-redux';
import Component from '../';
import fetchConfig from 'api/fetch-config';
import fetchPaymentLibrary from 'api/fetch-payment-library';
import fetchSnowplow from 'api/fetch-snowplow';
import * as selectors from 'selectors';

const mapStateToProps = (state) => ({
  isBootstrappingPending: selectors.isBootstrappingPendingSelector(state),
  hasBootstrappingCompletedSuccessfully: selectors.hasBootstrappingCompletedSuccessfullySelector(
    state
  ),
});

const mapDispatchToProps = (dispatch) => ({
  async onMount() {
    dispatch(fetchPaymentLibrary());
    await dispatch(fetchConfig());
    dispatch(fetchSnowplow());
  },
});

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(Component);

export default AppContainer;
