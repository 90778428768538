import React from 'react';
import PropTypes from 'prop-types';
import './stylesheets/generic-pay-button.scss';

const namespace = 'browser-pay-button';

const BrowserPayButton = ({ onClick }) => {
  return (
    <button
      className={namespace}
      onClick={onClick}
      aria-label="Pay using your Browser"
    >
      Pay using your Browser
    </button>
  );
};

BrowserPayButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default BrowserPayButton;
