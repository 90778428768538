import React from 'react';
import logoSrc from './images/lightspeed-logo.svg';
import './stylesheets/index.scss';

const namespace = 'page-footer';

const PageFooter = () => {
  return (
    <footer className={namespace}>
      <a
        className={`${namespace}__logo-link`}
        href="https://www.shopkeep.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className={`${namespace}__logo-link-copy`}>Powered by</span>
        <img
          className={`${namespace}__logo-link-image`}
          src={logoSrc}
          alt="ShopKeep by Lightspeed"
        />
      </a>

      <ul className={`${namespace}__legal-links`}>
        <li className={`${namespace}__legal-list-item`}>
          <a
            className={`${namespace}__legal-link`}
            href="https://www.shopkeep.com/security"
            target="_blank"
            rel="noopener noreferrer"
          >
            Security
          </a>
        </li>
        <li className={`${namespace}__legal-list-item`}>
          <a
            className={`${namespace}__legal-link`}
            href="https://www.shopkeep.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </li>
      </ul>
    </footer>
  );
};

export default PageFooter;
