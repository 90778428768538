import React, { useState, useEffect, useCallback } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'components/card';
import {
  paymentRequestTotalSelector,
  paymentRequestCurrencySelector,
} from 'selectors';
import ApplePayButton from './provider-buttons/apple-pay-button';
import GooglePayButton from './provider-buttons/google-pay-button';
import BrowserPayButton from './provider-buttons/browser-pay-button';
import { isChrome } from 'utils/browser';
import confirmWalletPayment from 'api/confirm-wallet-payment';
import './stylesheets/index.scss';

const namespace = 'payment-button';

const PaymentButton = () => {
  const dispatch = useDispatch();

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [hasApplePay, setHasApplePay] = useState(null);
  const stripe = useStripe();

  const amount = useSelector(paymentRequestTotalSelector);
  const currency = useSelector(paymentRequestCurrencySelector);

  const onPaymentMethod = useCallback(
    (event) => {
      dispatch(
        confirmWalletPayment({
          stripe,
          event,
        })
      );
    },
    [dispatch, stripe]
  );

  useEffect(() => {
    let isMounted = true;

    const paymentButtonPaymentRequest = stripe.paymentRequest({
      country: 'US',
      currency: currency,
      total: {
        label: 'Total',
        amount: amount,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    paymentButtonPaymentRequest.canMakePayment().then((result) => {
      if (result && isMounted) {
        setPaymentRequest(paymentButtonPaymentRequest);
        setHasApplePay(result.applePay);
      }
    });

    paymentButtonPaymentRequest.on('paymentmethod', onPaymentMethod);

    return () => {
      isMounted = false;
    };
  }, [amount, currency, onPaymentMethod, stripe]);

  const showPaymentRequest = () => {
    paymentRequest.show();
  };

  const renderPaymentButton = () => {
    if (hasApplePay) {
      return <ApplePayButton onClick={showPaymentRequest} />;
    } else if (isChrome()) {
      return <GooglePayButton onClick={showPaymentRequest} />;
    } else {
      return <BrowserPayButton onClick={showPaymentRequest} />;
    }
  };

  if (paymentRequest) {
    return (
      <Card>
        {renderPaymentButton()}
        <div className={`${namespace}__orText`}>or</div>
      </Card>
    );
  }

  return null;
};

export default PaymentButton;
