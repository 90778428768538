import Immutable from 'immutable';
import FormValidation from 'utils/form-validation';

const ruleset = Immutable.fromJS({
  cardholderName: [
    {
      validator: 'required',
      message: 'Cardholder name is incomplete.',
    },
  ],
  billingZipCode: [
    {
      validator: 'validRegex',
      validatorOptions: { regex: /^\d{5}$/ },
      message: 'Enter a valid 5 digit zip code.',
    },
  ],
});

export default new FormValidation({ ruleset });
