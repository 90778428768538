import React from 'react';
import './stylesheets/index.scss';

const namespace = 'page-not-found';

const PageNotFound = () => {
  return (
    <div className={namespace}>
      <h1 className={`${namespace}__error-code`}>404</h1>
      <p className={`${namespace}__error-message`}>
        The page you're looking for doesn't exist. The address may be incorrect
        or the page may have moved.
      </p>
    </div>
  );
};

export default PageNotFound;
