import once from 'lodash/once';
import { connect } from 'react-redux';
import Component from '../';
import fetchPaymentRequest from 'api/fetch-payment-request';
import fetchStoreInformation from 'api/fetch-store-information';
import { patchGratuityReset } from 'reducers/payment-request';
import * as selectors from 'selectors';

let stripeInstance;

const createStripeInstance = once((apiPublishableKey) => {
  stripeInstance = window.Stripe(apiPublishableKey);
});

const mapStateToProps = (state) => {
  createStripeInstance(selectors.stripeApiPublishableKeySelector(state));

  return {
    hasInvoicingChannel: selectors.hasInvoicingChannelSelector(state),
    hasPaymentRequestSucceeded: selectors.hasPaymentRequestSucceededSelector(
      state
    ),
    isPaymentRequestCanceled: selectors.isPaymentRequestCanceledSelector(state),
    isPaymentRequestMissing: selectors.isPaymentRequestMissingSelector(state),
    patchGratuityRequestErrorMessage: selectors.paymentRequestPatchGratuityRequestErrorMessageSelector(
      state
    ),
    showGratuityCard: selectors.showGratuityCardSelector(state),
    showLoader: selectors.isPaymentPageLoadingSelector(state),
    showPaymentContent: selectors.hasPaymentRequestLoadedSuccessfullySelector(
      state
    ),
    stripeInstance,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  async onMount() {
    await dispatch(fetchPaymentRequest(ownProps.paymentRequestUuid));
    dispatch(fetchStoreInformation());
  },
  dismissPatchGratuityRequestErrorMessage() {
    dispatch(patchGratuityReset());
  },
});

const PaymentPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default PaymentPageContainer;
