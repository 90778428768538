import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/card';
import './stylesheets/index.scss';

const namespace = 'text-card';

const TextCard = ({ title, subtitle }) => {
  return (
    <Card className={`${namespace}`}>
      <h2 className={`${namespace}__title`}>{title}</h2>
      <h2 className={`${namespace}__subtitle`}>{subtitle}</h2>
    </Card>
  );
};

TextCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default TextCard;
