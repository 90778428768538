import React from 'react';
import PropTypes from 'prop-types';
import './stylesheets/apple-pay-button.scss';

const namespace = 'apple-pay-button';

const ApplePayButton = ({ onClick }) => {
  return (
    <button
      className={namespace}
      onClick={onClick}
      aria-label="Check out with Apple Pay"
    />
  );
};

ApplePayButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ApplePayButton;
