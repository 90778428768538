import * as configReducerExports from 'reducers/config';

const fetchPaymentLibrary = () => {
  return (dispatch, getState) => {
    dispatch(configReducerExports.fetchPaymentLibraryPending());

    const libraryScriptElement = document.createElement('script');

    libraryScriptElement.onerror = () => {
      dispatch(configReducerExports.fetchPaymentLibraryFailure());
    };

    libraryScriptElement.onload = () => {
      dispatch(configReducerExports.fetchPaymentLibrarySuccess());
    };

    document.head.appendChild(libraryScriptElement);
    libraryScriptElement.src = 'https://js.stripe.com/v3/';
  };
};

export default fetchPaymentLibrary;
