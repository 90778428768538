import { connect } from 'react-redux';
import Component from '../';
import * as selectors from 'selectors';

const mapStateToProps = (state) => ({
  address: selectors.storeInformationAddressSelector(state),
  logoUrl: selectors.storeInformationLogoUrlSelector(state),
  showGratuityLine: selectors.showGratuityLineSelector(state),
  storeName: selectors.storeInformationNameSelector(state),
  subtotal: selectors.paymentRequestSubtotalAsDecimalSelector(state),
  tax: selectors.paymentRequestTaxAsDecimalSelector(state),
  total: selectors.paymentRequestTotalAsDecimalSelector(state),
});

const mapDispatchToProps = () => ({});

const InfoCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default InfoCardContainer;
