import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './stylesheets/index.scss';

const Modal = ({ children, open, onClose }) => {
  return (
    <Popup modal className="modal" onClose={onClose} open={open}>
      {(close) => (
        <React.Fragment>
          <button aria-label="Close" className="modal__close" onClick={close}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M0 0L14 14M0 14L14 0"
                transform="translate(-780 -226) translate(470 212) translate(292) translate(19 15)"
              />
            </svg>
          </button>
          {children}
        </React.Fragment>
      )}
    </Popup>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default Modal;
