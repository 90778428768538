import has from 'lodash/has';
import isFunction from 'lodash/isFunction';
import actionMappings from './action-mappings';
import { storeUuidSelector } from 'selectors';

const trackEvent = function (actionType, state) {
  const isNotProduction = process.env.NODE_ENV !== 'production';
  const missingActionMapping = !has(actionMappings, actionType);
  if (isNotProduction || missingActionMapping) return;

  const { action, property } = actionMappings[actionType];
  const normalisedProperty = isFunction(property) ? property(state) : property;

  const snowplowParams = {
    category: storeUuidSelector(state),
    action,
    label: undefined,
    property: JSON.stringify(normalisedProperty),
    value: undefined,
  };

  window.snowplow(
    'trackStructEvent',
    snowplowParams.category,
    snowplowParams.action,
    snowplowParams.label,
    snowplowParams.property,
    snowplowParams.value
  );
};

export default trackEvent;
