import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import cx from 'classnames';
import get from 'lodash/get';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import {
  optionsWithPlaceholder,
  optionsWithoutPlaceholder,
} from 'constants/stripe-element-options';
import './stylesheets/index.scss';
import Card from 'components/card';
import ErrorToast from 'components/error-toast';
import Button from 'components/button';
import FieldErrors from './field-errors';

const namespace = 'payment-card';

const PaymentCard = ({
  arePaymentRequestUpdatesBlocked,
  billingZipCodeValue,
  cardholderNameErrors,
  cardholderNameValue,
  cardNumberAndCvvErrors,
  expiryAndBillingZipCodeErrors,
  hasBillingZipCodeErrored,
  hasCardholderNameErrored,
  isPaymentFormIncomplete,
  isPaymentFormProcessing,
  onFieldBlur,
  onFieldChange,
  onPaymentButtonClick,
  onStripeChange,
  processingErrorMessage,
  resetProcessing,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  return (
    <React.Fragment>
      {processingErrorMessage && (
        <ErrorToast
          errorMessage={processingErrorMessage}
          dismissError={resetProcessing}
        />
      )}

      <Card className={namespace} headerText="Pay with Credit Card">
        <div
          className={cx({
            [`${namespace}__form`]: true,
            [`${namespace}__form--disabled`]: arePaymentRequestUpdatesBlocked,
          })}
        >
          <div className={`${namespace}__row ${namespace}__row--fields`}>
            <div className={`${namespace}__field`}>
              <label
                className={`${namespace}__label`}
                htmlFor="cardholder-name"
              >
                Cardholder Name
              </label>
              <input
                onBlur={() => {
                  onFieldBlur('cardholderName');
                }}
                onChange={(e) => {
                  onFieldChange({
                    id: 'cardholderName',
                    value: e.target.value,
                  });
                }}
                autoComplete="cc-name"
                className={cx({
                  [`${namespace}__input`]: true,
                  [`${namespace}__input--standard`]: true,
                  [`${namespace}__input--invalid`]: hasCardholderNameErrored,
                })}
                id="cardholder-name"
                name="cardholder-name"
                type="text"
                value={cardholderNameValue}
              />
            </div>
          </div>

          <FieldErrors
            className={`${namespace}__row ${namespace}__row--errors`}
            errors={cardholderNameErrors}
          />

          <div className={`${namespace}__row ${namespace}__row--fields`}>
            <div
              className={`${namespace}__field ${namespace}__field--card-number`}
            >
              <label className={`${namespace}__label`} htmlFor="card-number">
                Card Number
              </label>
              <CardNumberElement
                className={`${namespace}__input ${namespace}__input--stripe`}
                id="card-number"
                options={optionsWithoutPlaceholder}
                onChange={(e) => {
                  onStripeChange({
                    id: 'cardNumber',
                    error: get(e, 'error.message'),
                    complete: e.complete,
                  });
                }}
              />
            </div>

            <div className={`${namespace}__field ${namespace}__field--cvv`}>
              <label className={`${namespace}__label`} htmlFor="cvv">
                CVV
              </label>
              <CardCvcElement
                className={`${namespace}__input ${namespace}__input--stripe`}
                id="cvv"
                options={optionsWithoutPlaceholder}
                onChange={(e) => {
                  onStripeChange({
                    id: 'cvv',
                    error: get(e, 'error.message'),
                    complete: e.complete,
                  });
                }}
              />
            </div>
          </div>

          <FieldErrors
            className={`${namespace}__row ${namespace}__row--errors`}
            errors={cardNumberAndCvvErrors}
          />

          <div className={`${namespace}__row ${namespace}__row--fields`}>
            <div className={`${namespace}__field ${namespace}__field--expiry`}>
              <label className={`${namespace}__label`} htmlFor="expiry">
                Expiry
              </label>
              <CardExpiryElement
                className={`${namespace}__input ${namespace}__input--stripe`}
                id="expiry"
                options={optionsWithPlaceholder}
                onChange={(e) => {
                  onStripeChange({
                    id: 'expiry',
                    error: get(e, 'error.message'),
                    complete: e.complete,
                  });
                }}
              />
            </div>

            <div className={`${namespace}__field ${namespace}__field--zip`}>
              <label
                className={`${namespace}__label`}
                htmlFor="billing-zip-code"
              >
                Billing Zip Code
              </label>
              <input
                onBlur={() => {
                  onFieldBlur('billingZipCode');
                }}
                onChange={(e) => {
                  onFieldChange({
                    id: 'billingZipCode',
                    value: e.target.value,
                  });
                }}
                autoComplete="postal-code"
                className={cx({
                  [`${namespace}__input`]: true,
                  [`${namespace}__input--standard`]: true,
                  [`${namespace}__input--invalid`]: hasBillingZipCodeErrored,
                })}
                id="billing-zip-code"
                name="billing-zip-code"
                type="number"
                value={billingZipCodeValue}
              />
            </div>
          </div>

          <FieldErrors
            className={`${namespace}__row ${namespace}__row--errors`}
            errors={expiryAndBillingZipCodeErrors}
          />
        </div>

        <Button
          className={`${namespace}__button`}
          disabled={isPaymentFormIncomplete || arePaymentRequestUpdatesBlocked}
          pending={isPaymentFormProcessing}
          onClick={() => onPaymentButtonClick({ stripe, elements })}
        >
          Pay Now
        </Button>
      </Card>
    </React.Fragment>
  );
};

PaymentCard.propTypes = {
  arePaymentRequestUpdatesBlocked: PropTypes.bool.isRequired,
  billingZipCodeValue: PropTypes.string.isRequired,
  cardholderNameErrors: ImmutablePropTypes.list.isRequired,
  cardholderNameValue: PropTypes.string.isRequired,
  cardNumberAndCvvErrors: ImmutablePropTypes.list.isRequired,
  expiryAndBillingZipCodeErrors: ImmutablePropTypes.list.isRequired,
  hasBillingZipCodeErrored: PropTypes.bool.isRequired,
  hasCardholderNameErrored: PropTypes.bool.isRequired,
  isPaymentFormIncomplete: PropTypes.bool.isRequired,
  isPaymentFormProcessing: PropTypes.bool.isRequired,
  onFieldBlur: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onPaymentButtonClick: PropTypes.func.isRequired,
  onStripeChange: PropTypes.func.isRequired,
  processingErrorMessage: PropTypes.string,
  resetProcessing: PropTypes.func.isRequired,
};

export default PaymentCard;
