import React from 'react';
import PropTypes from 'prop-types';
import formatNumber from 'utils/format-number';

const FormattedNumber = ({ className, value }) => {
  return <span className={className}>{formatNumber(value)}</span>;
};

FormattedNumber.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number.isRequired,
};

export default FormattedNumber;
