import isNumber from 'lodash/isNumber';
import Decimal from 'decimal.js-light';

export const conversionFactory = function (decimalMethod) {
  return (amount, options = {}) => {
    if (!isNumber(amount) || !isNumber(options.decimalPlaces)) return NaN;

    const converter = new Decimal(10).toPower(options.decimalPlaces);
    const decimalObj = new Decimal(amount);
    return decimalObj[decimalMethod](converter).toNumber();
  };
};

export const convertDecimalAmountToInteger = conversionFactory('times');
export const convertIntegerAmountToDecimal = conversionFactory('dividedBy');
