import React from 'react';
import PropTypes from 'prop-types';
import './stylesheets/index.scss';

const namespace = 'error-toast';

const ErrorToast = ({ dismissError, errorMessage }) => {
  return (
    <div className={namespace}>
      <span className={`${namespace}__text`}>{errorMessage}</span>
      <button
        aria-label="dismiss error"
        className={`${namespace}__dismiss`}
        onClick={dismissError}
      >
        x
      </button>
    </div>
  );
};

ErrorToast.propTypes = {
  dismissError: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

export default ErrorToast;
