import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';
import './stylesheets/index.scss';
import Spinner from 'components/spinner';

const namespace = 'button';

const Button = ({
  children,
  className,
  disabled = false,
  onClick,
  pending,
  theme = 'main',
}) => {
  const containerClasses = cx({
    [className]: !isEmpty(className),
    [namespace]: true,
    [`${namespace}--${theme}`]: true,
    [`${namespace}--disabled`]: disabled,
    [`${namespace}--pending`]: pending,
  });

  return (
    <div className={containerClasses}>
      <button
        disabled={disabled || pending}
        className={`${namespace}__cta`}
        onClick={onClick}
      >
        <span className={`${namespace}__text`}>{children}</span>
      </button>
      {isBoolean(pending) && (
        <div className={`${namespace}__spinner`}>
          <Spinner
            animate={pending}
            mode={theme === 'main' ? 'light' : 'dark'}
          />
        </div>
      )}
    </div>
  );
};

Button.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  pending: PropTypes.bool,
  theme: PropTypes.oneOf(['main', 'alt']),
};

export default Button;
