import {
  FETCH_PAYMENT_REQUEST_SUCCESS,
  PATCH_GRATUITY_FAILURE,
  PATCH_GRATUITY_SUCCESS,
} from 'reducers/payment-request';
import {
  PAYMENT_FORM_PROCESSING_PENDING,
  PAYMENT_FORM_PROCESSING_SUCCESS,
  PAYMENT_FORM_PROCESSING_FAILURE,
} from 'reducers/payment-form';
import { fetchPaymentRequestSuccessPropertySelector } from 'selectors';

const actionMapping = {
  [FETCH_PAYMENT_REQUEST_SUCCESS]: {
    action: 'invoices.payment_portal.ui.arrive_on_page',
    property: fetchPaymentRequestSuccessPropertySelector,
  },
  [PAYMENT_FORM_PROCESSING_PENDING]: {
    action: 'invoices.payment_portal.ui.click_pay_now',
  },
  [PAYMENT_FORM_PROCESSING_SUCCESS]: {
    action: 'invoices.payment_portal.ui.payment_confirmation',
    property: { outcome: 'success' },
  },
  [PAYMENT_FORM_PROCESSING_FAILURE]: {
    action: 'invoices.payment_portal.ui.payment_confirmation',
    property: { outcome: 'failure' },
  },
  [PATCH_GRATUITY_FAILURE]: {
    action: 'invoices.payment_portal.ui.gratuity',
    property: { outcome: 'failure' },
  },
  [PATCH_GRATUITY_SUCCESS]: {
    action: 'invoices.payment_portal.ui.gratuity',
    property: { outcome: 'success' },
  },
};

export default actionMapping;
