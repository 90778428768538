import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import './stylesheets/index.scss';
import InfoCard from 'components/info-card/containers';
import PaymentCard from 'components/payment-card/containers';
import ErrorToast from 'components/error-toast';
import LoadingCard from 'components/loading-card';
import GenericErrorCard from 'components/generic-error-card';
import GratuityCard from 'components/gratuity-card';
import TextCard from 'components/text-card';
import PaymentButton from 'components/payment-button';
import { SUCCESS_REDIRECT_DELAY } from 'constants/redirect-delay';

const namespace = 'payment-page';

const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

const renderTextCard = ({ title, subtitle }) => {
  return <TextCard title={title} subtitle={subtitle} />;
};

const renderInfoCardAndTextCard = ({ title, subtitle }) => {
  return (
    <React.Fragment>
      <InfoCard />
      {renderTextCard({ title, subtitle })}
    </React.Fragment>
  );
};

const PaymentPage = ({
  dismissPatchGratuityRequestErrorMessage,
  hasInvoicingChannel,
  hasPaymentRequestSucceeded,
  isPaymentRequestCanceled,
  isPaymentRequestMissing,
  onMount,
  patchGratuityRequestErrorMessage,
  showGratuityCard,
  showLoader,
  showPaymentContent,
  stripeInstance,
  successUrl,
}) => {
  useEffect(() => {
    onMount();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!hasPaymentRequestSucceeded || !successUrl) return;

    const redirectWithDelay = async () => {
      await sleep(SUCCESS_REDIRECT_DELAY);
      window.location.assign(successUrl);
    };

    redirectWithDelay();
  }, [hasPaymentRequestSucceeded, successUrl]);

  const renderContent = () => {
    if (showLoader) return <LoadingCard />;

    if (isPaymentRequestMissing)
      return renderTextCard({
        title: 'Please Try Again',
        subtitle:
          "Your link didn't load for some reason. Refresh the page in your browser to pay.",
      });

    if (hasPaymentRequestSucceeded) {
      return renderInfoCardAndTextCard({
        title: 'Thank you for your payment!',
        subtitle: hasInvoicingChannel
          ? 'Please check your email for order confirmation.'
          : '',
      });
    }

    if (isPaymentRequestCanceled)
      return renderInfoCardAndTextCard({
        title: 'This Order has been closed',
        subtitle: 'Please contact the seller for more information.',
      });

    if (showPaymentContent)
      return (
        <React.Fragment>
          <InfoCard />
          {showGratuityCard && <GratuityCard />}
          <PaymentButton />
          <PaymentCard />
        </React.Fragment>
      );

    return <GenericErrorCard />;
  };

  return (
    <Elements
      stripe={stripeInstance}
      options={{
        fonts: [
          { cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans:300' },
        ],
      }}
    >
      {patchGratuityRequestErrorMessage && (
        <ErrorToast
          errorMessage={patchGratuityRequestErrorMessage}
          dismissError={dismissPatchGratuityRequestErrorMessage}
        />
      )}

      <div className={namespace}>
        <div className={`${namespace}__cards-container`}>{renderContent()}</div>
      </div>
    </Elements>
  );
};

PaymentPage.propTypes = {
  dismissPatchGratuityRequestErrorMessage: PropTypes.func.isRequired,
  hasInvoicingChannel: PropTypes.bool.isRequired,
  hasPaymentRequestSucceeded: PropTypes.bool.isRequired,
  isPaymentRequestCanceled: PropTypes.bool.isRequired,
  isPaymentRequestMissing: PropTypes.bool.isRequired,
  onMount: PropTypes.func.isRequired,
  patchGratuityRequestErrorMessage: PropTypes.string,
  showGratuityCard: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool.isRequired,
  showPaymentContent: PropTypes.bool.isRequired,
  stripeInstance: PropTypes.object.isRequired,
  successUrl: PropTypes.string,
};

export default PaymentPage;
