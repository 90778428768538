import axios from 'axios';
import * as storeInformationReducerExports from 'reducers/store-information';
import { formatStoreInformationPayload } from './formatters';
import { apiGatewayUrlSelector, storeUuidSelector } from 'selectors';
import { isEmpty } from 'lodash';

const fetchStoreInformation = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const storeUuid = storeUuidSelector(state);

    if (isEmpty(storeUuid)) return;
    dispatch(storeInformationReducerExports.fetchStoreInformationPending());

    const apiGatewayUrl = apiGatewayUrlSelector(state);
    let response;

    try {
      response = await axios({
        method: 'get',
        url: `${apiGatewayUrl}/services/stores/${storeUuid}/receipt_settings`,
      });
    } catch (e) {
      return dispatch(
        storeInformationReducerExports.fetchStoreInformationFailure()
      );
    }

    const formattedData = formatStoreInformationPayload(response.data);

    dispatch(
      storeInformationReducerExports.fetchStoreInformationSuccess(formattedData)
    );
  };
};

export default fetchStoreInformation;
