import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import isString from 'lodash/isString';
import Button from 'components/button';
import * as i18nConstants from 'constants/i18n';
import * as suggestedTipTypes from 'constants/suggested-tip-types';
import { convertIntegerAmountToDecimal } from 'utils/currency';
import values from 'lodash/values';
import formatCurrency from 'utils/format-currency';

const formatTipText = (tip) => {
  if (tip.get('type') === suggestedTipTypes.PERCENT) {
    return `${tip.get('value')}%`;
  }

  const decimalValue = convertIntegerAmountToDecimal(tip.get('value'), {
    decimalPlaces: i18nConstants.defaultCurrencyPrecision,
  });

  return formatCurrency(decimalValue);
};

const TipToggle = ({ onClick, tip, selected }) => {
  return (
    <Button
      className="gratuity-card__tip"
      onClick={() => onClick(tip)}
      theme={selected ? 'main' : 'alt'}
    >
      {isString(tip) ? tip : formatTipText(tip)}
    </Button>
  );
};

TipToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  tip: PropTypes.oneOfType([
    PropTypes.string,
    ImmutablePropTypes.mapContains({
      type: PropTypes.oneOf(values(suggestedTipTypes)).isRequired,
      value: PropTypes.number.isRequired,
    }),
  ]).isRequired,
  selected: PropTypes.bool.isRequired,
};

export default TipToggle;
