import I18n from 'i18n-js';
import * as i18nConstants from 'constants/i18n';

const formatCurrency = function (
  value,
  {
    precision = i18nConstants.defaultCurrencyPrecision,
    unit = i18nConstants.defaultCurrencySymbol,
  } = {}
) {
  return I18n.toCurrency(value, {
    precision,
    unit,
  });
};

export default formatCurrency;
