import get from 'lodash/get';
import * as paymentFormReducerExports from 'reducers/payment-form';
import * as paymentRequestReducerExports from 'reducers/payment-request';
import * as paymentIntentStates from 'constants/payment-intent-states';
import {
  paymentFormFieldValueSelector,
  paymentIntentClientSecretSelector,
} from 'selectors';

const confirmCardPayment = ({ stripe, elements }) => {
  return async (dispatch, getState) => {
    dispatch(paymentFormReducerExports.paymentFormProcessingPending());

    const state = getState();
    const clientSecret = paymentIntentClientSecretSelector(state);
    const billingZipCode = paymentFormFieldValueSelector(
      state,
      'billingZipCode'
    );
    const cardholderName = paymentFormFieldValueSelector(
      state,
      'cardholderName'
    );

    try {
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement('cardNumber'),
          billing_details: {
            name: cardholderName,
            address: {
              postal_code: billingZipCode,
            },
          },
        },
      });

      if (result.error) throw result;
      dispatch(paymentFormReducerExports.paymentFormProcessingSuccess());
    } catch (e) {
      const paymentIntentStatus = get(e, 'error.payment_intent.status');

      if (paymentIntentStatus === paymentIntentStates.SUCCEEDED) {
        dispatch(paymentFormReducerExports.paymentFormProcessingSuccess());
        return;
      }

      if (paymentIntentStatus === paymentIntentStates.CANCELED) {
        dispatch(paymentRequestReducerExports.paymentRequestMarkAsCanceled());
        return;
      }

      const errorMessage = get(
        e,
        'error.message',
        'An error has occurred. Please try again.'
      );

      dispatch(
        paymentFormReducerExports.paymentFormProcessingFailure(errorMessage)
      );
    }
  };
};

export default confirmCardPayment;
