import { defaultCurrencyPrecision } from 'constants/i18n';
import * as suggestedTipTypes from 'constants/suggested-tip-types';
import { convertDecimalAmountToInteger } from 'utils/currency';
import { precisionPercentageOf, precisionRounding } from 'utils/number';

const calculateGratuity = (
  suggestedTip,
  totalWithoutGratuity,
  parsedInputValue
) => {
  if (suggestedTip) {
    return suggestedTip.get('type') === suggestedTipTypes.AMOUNT
      ? suggestedTip.get('value')
      : precisionPercentageOf({
          value: totalWithoutGratuity,
          percentage: suggestedTip.get('value'),
          decimalPlaces: 0,
        });
  }

  const roundedInput = precisionRounding({
    value: parsedInputValue,
    decimalPlaces: defaultCurrencyPrecision,
  });

  return convertDecimalAmountToInteger(roundedInput, {
    decimalPlaces: defaultCurrencyPrecision,
  });
};

export default calculateGratuity;
