import { SET_FEATURE_STATUS } from 'reducers/features';
import { featuresSelector } from 'selectors';

const featuresPersist = ({ getState }) => (next) => (action) => {
  const result = next(action);
  if (action.type !== SET_FEATURE_STATUS) return result;

  const featuresState = featuresSelector(getState());
  try {
    window.sessionStorage.setItem('features', JSON.stringify(featuresState));
  } catch (err) {}

  return result;
};

export default featuresPersist;
