import { createStore, applyMiddleware, compose } from 'redux';
import { combineReducers } from 'redux-immutable';
import thunk from 'redux-thunk';
import featuresPersist from 'middleware/features-persist';
import snowplow from 'middleware/snowplow';
import config from 'reducers/config';
import features from 'reducers/features';
import paymentForm from 'reducers/payment-form';
import paymentRequest from 'reducers/payment-request';
import storeInformation from 'reducers/store-information';

export const middleware = [thunk, featuresPersist, snowplow];

const reduxDevtoolsOptions =
  process.env.NODE_ENV === 'production' ? { features: {} } : {};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(reduxDevtoolsOptions)
  : compose;

const rootReducer = combineReducers({
  config,
  features,
  paymentForm,
  paymentRequest,
  storeInformation,
});

export default createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware))
);
