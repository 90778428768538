import Immutable from 'immutable';

const fieldIdToLabel = Immutable.Map({
  billingZipCode: 'Billing Zip Code',
  cardholderName: 'Cardholder Name',
  cardNumber: 'Card Number',
  cvv: 'CVV',
  expiry: 'Expiry',
});

export default fieldIdToLabel;
