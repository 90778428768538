import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import isUndefined from 'lodash/isUndefined';
import isNaN from 'lodash/isNaN';
import {
  arePaymentRequestUpdatesBlockedSelector,
  isPaymentRequestPatchGratuityPendingSelector,
  paymentRequestIdSelector,
  paymentRequestTotalWithoutGratuitySelector,
  suggestedTipsSelector,
} from 'selectors';
import patchGratuity from 'api/patch-gratuity';
import Card from 'components/card';
import Button from 'components/button';
import { defaultCurrencySymbol } from 'constants/i18n';
import calculateGratuity from 'utils/calculate-gratuity';
import TipToggle from './tip-toggle';
import ConfirmModal from './confirm-modal';
import './stylesheets/index.scss';

const namespace = 'gratuity-card';

const SHOW_GRATUITY_MODAL_THRESHOLD = 50;

const GratuityCard = () => {
  const dispatch = useDispatch();
  const manualInputRef = useRef();

  const [selectedSuggestedTip, setSelectedSuggestedTip] = useState();
  const [manualInputValue, setManualInputValue] = useState('');
  const [parsedInputValue, setParsedInputValue] = useState(0);
  const [showManualInput, setShowManualInput] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const suggestedTips = useSelector(suggestedTipsSelector);
  const paymentRequestId = useSelector(paymentRequestIdSelector);
  const totalWithoutGratuity = useSelector(
    paymentRequestTotalWithoutGratuitySelector
  );
  const isRequestPending = useSelector(
    isPaymentRequestPatchGratuityPendingSelector
  );
  const arePaymentRequestUpdatesBlocked = useSelector(
    arePaymentRequestUpdatesBlockedSelector
  );

  const applyGratuity = useCallback(
    (gratuity) => {
      dispatch(
        patchGratuity({
          paymentRequestId,
          gratuity,
        })
      );
    },
    [dispatch, paymentRequestId]
  );

  const disableApplyGratuity =
    arePaymentRequestUpdatesBlocked ||
    (isUndefined(selectedSuggestedTip) &&
      (isNaN(parsedInputValue) || parsedInputValue <= 0));

  const onApplyClick = useCallback(() => {
    const gratuity = calculateGratuity(
      selectedSuggestedTip,
      totalWithoutGratuity,
      parsedInputValue
    );

    const percentageOfTotal = (gratuity / totalWithoutGratuity) * 100;
    if (percentageOfTotal > SHOW_GRATUITY_MODAL_THRESHOLD) {
      setShowModal(true);
      return;
    }

    applyGratuity(gratuity);
  }, [
    applyGratuity,
    parsedInputValue,
    selectedSuggestedTip,
    totalWithoutGratuity,
  ]);

  const onSuggestedTipClick = useCallback((tip) => {
    setSelectedSuggestedTip(tip);
    setShowManualInput(false);
  }, []);

  const onOtherTipClick = useCallback(() => {
    setSelectedSuggestedTip();
    setShowManualInput(true);
  }, []);

  const onModalClose = useCallback(() => {
    setShowModal(false);
  }, []);

  const onModalConfirm = useCallback(() => {
    setShowModal(false);

    const gratuity = calculateGratuity(
      selectedSuggestedTip,
      totalWithoutGratuity,
      parsedInputValue
    );

    applyGratuity(gratuity);
  }, [
    applyGratuity,
    parsedInputValue,
    selectedSuggestedTip,
    totalWithoutGratuity,
  ]);

  useEffect(() => {
    if (showManualInput) {
      manualInputRef.current.focus();
    } else {
      setManualInputValue('');
      setParsedInputValue(0);
    }
  }, [showManualInput]);

  const onManualInputChange = (e) => {
    const numericRepresentation =
      e.currentTarget.value.length > 0 ? parseFloat(e.currentTarget.value) : 0;

    setManualInputValue(e.currentTarget.value);
    setParsedInputValue(numericRepresentation);
  };

  return (
    <React.Fragment>
      <ConfirmModal
        isOpen={showModal}
        onConfirm={onModalConfirm}
        onClose={onModalClose}
        threshold={SHOW_GRATUITY_MODAL_THRESHOLD}
      />
      <Card className={namespace} headerText="Gratuity">
        <div
          className={cx({
            [`${namespace}__form`]: true,
            [`${namespace}__form--disabled`]: arePaymentRequestUpdatesBlocked,
          })}
        >
          <div className={`${namespace}__tips-container`}>
            {suggestedTips.map((tip, index) => (
              <TipToggle
                key={index}
                onClick={onSuggestedTipClick}
                selected={tip === selectedSuggestedTip}
                tip={tip}
              />
            ))}
            <TipToggle
              onClick={onOtherTipClick}
              selected={showManualInput}
              tip="Other"
            />
          </div>

          {showManualInput && (
            <div className={`${namespace}__input-container`}>
              <span className={`${namespace}__symbol`} aria-hidden="true">
                {defaultCurrencySymbol}
              </span>
              <input
                className={`${namespace}__input`}
                id="manual-gratuity"
                name="manual-gratuity"
                placeholder="0"
                onChange={onManualInputChange}
                ref={manualInputRef}
                type="number"
                value={manualInputValue}
              />
            </div>
          )}
        </div>

        <Button
          className={`${namespace}__button`}
          disabled={disableApplyGratuity}
          pending={isRequestPending}
          onClick={onApplyClick}
        >
          Apply
        </Button>
      </Card>
    </React.Fragment>
  );
};

export default GratuityCard;
