import Immutable from 'immutable';
import camelize from 'camelize';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import { convertDecimalAmountToInteger } from '../utils/currency';
import * as i18nConstants from '../constants/i18n';
import * as suggestedTipTypes from '../constants/suggested-tip-types';

export const formatPaymentRequestPayload = (responseBody) => {
  const immutableResponse = Immutable.fromJS(camelize(responseBody));

  return Immutable.fromJS({
    id: immutableResponse.get('id'),
    channel: immutableResponse.get('channel'),
    currency: immutableResponse.get('currency'),
    paymentIntentClientSecret: immutableResponse.getIn([
      'paymentIntent',
      'clientSecret',
    ]),
    status: immutableResponse.get('status'),
    storeUuid: immutableResponse.get('storeUuid'),
    subtotal: immutableResponse.get('subtotal'),
    tax: immutableResponse.get('tax'),
    gratuity: immutableResponse.get('gratuity'),
    total: immutableResponse.get('total'),
  });
};

export const formatStoreInformationPayload = (responseBody) => {
  const immutableResponse = Immutable.fromJS(camelize(responseBody));

  const suggestedTips = immutableResponse
    .getIn(['tipSettings', 'suggestedTips'], Immutable.List())
    .map((suggestedTip) => {
      const type = suggestedTip.get('type');
      const value = parseFloat(suggestedTip.get('value'));
      const normalisedValue =
        type === suggestedTipTypes.PERCENT
          ? value
          : convertDecimalAmountToInteger(value, {
              decimalPlaces: i18nConstants.defaultCurrencyPrecision,
            });

      return suggestedTip.set('value', normalisedValue);
    });

  return immutableResponse
    .map(trim)
    .filter((value) => !isEmpty(value))
    .merge({
      tipSettings: {
        enabled: immutableResponse.getIn(['tipSettings', 'enabled']),
        suggestedTips,
      },
    });
};
