import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import 'normalize.css';
import './stylesheets/index.scss';
import PageNotFound from 'components/page-not-found';
import PageFooter from 'components/page-footer';
import PaymentPage from 'components/payment-page/containers';
import LoadingCard from 'components/loading-card';
import GenericErrorCard from 'components/generic-error-card';
import queryString from 'query-string';

const namespace = 'app';

const App = ({
  isBootstrappingPending,
  hasBootstrappingCompletedSuccessfully,
  onMount,
}) => {
  useEffect(() => {
    onMount();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={namespace}>
      {hasBootstrappingCompletedSuccessfully ? (
        <Switch>
          <Route
            exact
            path="/payment/:paymentRequestUuid"
            render={({ match: { params }, location: { search } }) => {
              const { successUrl } = queryString.parse(search);
              return (
                <PaymentPage
                  paymentRequestUuid={params.paymentRequestUuid}
                  successUrl={successUrl}
                />
              );
            }}
          />
          <Route component={PageNotFound} />
        </Switch>
      ) : (
        <div className={`${namespace}__cards-container`}>
          {isBootstrappingPending ? <LoadingCard /> : <GenericErrorCard />}
        </div>
      )}
      <PageFooter />
    </div>
  );
};

App.propTypes = {
  isBootstrappingPending: PropTypes.bool.isRequired,
  hasBootstrappingCompletedSuccessfully: PropTypes.bool.isRequired,
  onMount: PropTypes.func.isRequired,
};

export default App;
