import Immutable from 'immutable';
import isNull from 'lodash/isNull';

export const SET_FEATURE_STATUS = 'SET_FEATURE_STATUS';

export const setFeatureStatus = ({ feature, active }) => ({
  type: SET_FEATURE_STATUS,
  feature,
  active,
});

const getStateFromStorage = () => {
  try {
    const valueFromStorage = window.sessionStorage.getItem('features');
    if (isNull(valueFromStorage)) return Immutable.Map();
    return Immutable.Map(JSON.parse(valueFromStorage));
  } catch (err) {
    return Immutable.Map();
  }
};

export default (state = getStateFromStorage(), action) => {
  switch (action.type) {
    case SET_FEATURE_STATUS:
      return state.set(action.feature, action.active);
    default:
      return state;
  }
};
