import Immutable from 'immutable';
import { createSelector } from 'reselect';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import some from 'lodash/some';
import trim from 'lodash/trim';
import * as i18nConstants from 'constants/i18n';
import fieldIdToLabel from 'constants/field-id-to-label';
import * as paymentRequestStatuses from 'constants/payment-request-statuses';
import * as paymentRequestChannels from 'constants/payment-request-channels';
import * as submissionStates from 'constants/submission-states';
import * as configReducerExports from 'reducers/config';
import * as paymentFormReducerExports from 'reducers/payment-form';
import * as paymentRequestReducerExports from 'reducers/payment-request';
import * as storeInformationReducerExports from 'reducers/store-information';
import { convertIntegerAmountToDecimal } from 'utils/currency';
import paymentFormValidator from 'utils/payment-form-validator';

const configSelector = (state) => state.get('config', Immutable.Map());

export const featuresSelector = (state) =>
  state.get('features', Immutable.Map());

const paymentFormSelector = (state) =>
  state.get('paymentForm', Immutable.Map());

const paymentRequestSelector = (state) =>
  state.get('paymentRequest', Immutable.Map());

const storeInformationSelector = (state) =>
  state.get('storeInformation', Immutable.Map());

const configLoadingStateSelector = createSelector(
  configSelector,
  configReducerExports.loadingStateSelector
);

const paymentLibraryLoadingStateSelector = createSelector(
  configSelector,
  configReducerExports.paymentLibraryLoadingStateSelector
);

const paymentRequestLoadingStateSelector = createSelector(
  paymentRequestSelector,
  paymentRequestReducerExports.loadingStateSelector
);

const paymentRequestPatchGratuityRequestStateSelector = createSelector(
  paymentRequestSelector,
  paymentRequestReducerExports.patchGratuityRequestStateSelector
);

export const isPaymentRequestPatchGratuityPendingSelector = createSelector(
  paymentRequestPatchGratuityRequestStateSelector,
  (patchGratuityRequestState) =>
    patchGratuityRequestState === submissionStates.PENDING
);

export const paymentRequestIdSelector = createSelector(
  paymentRequestSelector,
  paymentRequestReducerExports.paymentRequestIdSelector
);

export const paymentRequestPatchGratuityRequestErrorMessageSelector = createSelector(
  paymentRequestSelector,
  paymentRequestReducerExports.patchGratuityRequestErrorMessageSelector
);

const paymentRequestLoadingStatusErrorCodeSelector = createSelector(
  paymentRequestSelector,
  paymentRequestReducerExports.loadingStatusErrorCodeSelector
);

const storeInformationLoadingStateSelector = createSelector(
  storeInformationSelector,
  storeInformationReducerExports.loadingStateSelector
);

const isStoreInformationPendingSelector = createSelector(
  storeInformationLoadingStateSelector,
  (storeInformationLoadingState) =>
    storeInformationLoadingState === submissionStates.PENDING
);

const isPaymentRequestPendingSelector = createSelector(
  paymentRequestLoadingStateSelector,
  (paymentRequestLoadingState) => {
    return (
      paymentRequestLoadingState === submissionStates.INITIAL ||
      paymentRequestLoadingState === submissionStates.PENDING
    );
  }
);

export const isPaymentPageLoadingSelector = createSelector(
  isStoreInformationPendingSelector,
  isPaymentRequestPendingSelector,
  (...statuses) => some(statuses)
);

export const hasPaymentRequestLoadedSuccessfullySelector = createSelector(
  paymentRequestLoadingStateSelector,
  (paymentRequestLoadingState) =>
    paymentRequestLoadingState === submissionStates.SUCCESS
);

const hasConfigLoadedSuccessfullySelector = createSelector(
  configLoadingStateSelector,
  (configLoadingState) => configLoadingState === submissionStates.SUCCESS
);

const hasPaymentLibraryLoadedSuccessfullySelector = createSelector(
  paymentLibraryLoadingStateSelector,
  (paymentLibraryLoadingState) =>
    paymentLibraryLoadingState === submissionStates.SUCCESS
);

export const apiGatewayUrlSelector = createSelector(
  configSelector,
  configReducerExports.apiGatewayUrlSelector
);

export const storeUuidSelector = createSelector(
  paymentRequestSelector,
  paymentRequestReducerExports.storeUuidSelector
);

export const snowplowAppIdSelector = createSelector(
  configSelector,
  configReducerExports.snowplowAppIdSelector
);

export const snowplowCollectorUrlSelector = createSelector(
  configSelector,
  configReducerExports.snowplowCollectorUrlSelector
);

export const stripeApiPublishableKeySelector = createSelector(
  configSelector,
  configReducerExports.stripeApiPublishableKeySelector
);

const paymentRequestStatusSelector = createSelector(
  paymentRequestSelector,
  paymentRequestReducerExports.statusSelector
);

export const paymentRequestCurrencySelector = createSelector(
  paymentRequestSelector,
  paymentRequestReducerExports.currencySelector
);

export const isPaymentRequestMissingSelector = createSelector(
  paymentRequestLoadingStatusErrorCodeSelector,
  (paymentRequestLoadingStatusErrorCode) =>
    paymentRequestLoadingStatusErrorCode === 404
);

const isPaymentRequestEditableSelector = createSelector(
  paymentRequestStatusSelector,
  (paymentRequestStatus) =>
    paymentRequestStatus === paymentRequestStatuses.PENDING ||
    paymentRequestStatus === paymentRequestStatuses.PAYMENT_FAILED
);

export const isPaymentRequestCanceledSelector = createSelector(
  paymentRequestStatusSelector,
  (paymentRequestStatus) =>
    paymentRequestStatus === paymentRequestStatuses.CANCELED
);

export const hasPaymentRequestSucceededSelector = createSelector(
  paymentRequestStatusSelector,
  (paymentRequestStatus) =>
    paymentRequestStatus === paymentRequestStatuses.SUCCEEDED
);

const paymentRequestSubtotalSelector = createSelector(
  paymentRequestSelector,
  paymentRequestReducerExports.subtotalSelector
);

export const paymentRequestSubtotalAsDecimalSelector = createSelector(
  paymentRequestSubtotalSelector,
  (subtotal) => {
    return convertIntegerAmountToDecimal(subtotal, {
      decimalPlaces: i18nConstants.defaultCurrencyPrecision,
    });
  }
);

const paymentRequestTaxSelector = createSelector(
  paymentRequestSelector,
  paymentRequestReducerExports.taxSelector
);

export const paymentRequestTaxAsDecimalSelector = createSelector(
  paymentRequestTaxSelector,
  (tax) => {
    return convertIntegerAmountToDecimal(tax, {
      decimalPlaces: i18nConstants.defaultCurrencyPrecision,
    });
  }
);

const paymentRequestGratuitySelector = createSelector(
  paymentRequestSelector,
  paymentRequestReducerExports.gratuitySelector
);

export const paymentRequestGratuityAsDecimalSelector = createSelector(
  paymentRequestGratuitySelector,
  (gratuity) => {
    return convertIntegerAmountToDecimal(gratuity, {
      decimalPlaces: i18nConstants.defaultCurrencyPrecision,
    });
  }
);

const hasGratuityPresentSelector = createSelector(
  paymentRequestGratuitySelector,
  (gratuity) => gratuity > 0
);

export const paymentRequestTotalSelector = createSelector(
  paymentRequestSelector,
  paymentRequestReducerExports.totalSelector
);

export const paymentRequestTotalAsDecimalSelector = createSelector(
  paymentRequestTotalSelector,
  (total) => {
    return convertIntegerAmountToDecimal(total, {
      decimalPlaces: i18nConstants.defaultCurrencyPrecision,
    });
  }
);

export const paymentRequestTotalWithoutGratuitySelector = createSelector(
  paymentRequestTotalSelector,
  paymentRequestGratuitySelector,
  (total, gratuity) => total - gratuity
);

const paymentRequestChannelSelector = createSelector(
  paymentRequestSelector,
  paymentRequestReducerExports.channelSelector
);

const hasPayByLinkChannelSelector = createSelector(
  paymentRequestChannelSelector,
  (channel) => channel === paymentRequestChannels.PAY_BY_LINK
);

export const hasInvoicingChannelSelector = createSelector(
  paymentRequestChannelSelector,
  (channel) => channel === paymentRequestChannels.INVOICING
);

export const isBootstrappingPendingSelector = createSelector(
  configLoadingStateSelector,
  paymentLibraryLoadingStateSelector,
  (...loadingStates) => {
    return some(loadingStates, (loadingState) => {
      return (
        loadingState === submissionStates.INITIAL ||
        loadingState === submissionStates.PENDING
      );
    });
  }
);

export const hasBootstrappingCompletedSuccessfullySelector = createSelector(
  hasConfigLoadedSuccessfullySelector,
  hasPaymentLibraryLoadedSuccessfullySelector,
  (...loadingStates) => every(loadingStates)
);

export const paymentFormProcessingErrorMessageSelector = createSelector(
  paymentFormSelector,
  paymentFormReducerExports.processingErrorMessageSelector
);

const paymentFormProcessingStatusSelector = createSelector(
  paymentFormSelector,
  paymentFormReducerExports.processingStatusSelector
);

export const isPaymentFormProcessingSelector = createSelector(
  paymentFormProcessingStatusSelector,
  (processingStatus) => processingStatus === submissionStates.PENDING
);

export const paymentIntentClientSecretSelector = createSelector(
  paymentRequestSelector,
  paymentRequestReducerExports.paymentIntentClientSecretSelector
);

export const paymentFormFieldValueSelector = (state, fieldId) => {
  const paymentFormState = paymentFormSelector(state);

  return paymentFormReducerExports.formFieldValueSelector(
    paymentFormState,
    fieldId
  );
};

const formDataSelector = createSelector(
  paymentFormSelector,
  paymentFormReducerExports.formDataSelector
);

const stripeDataSelector = createSelector(
  paymentFormSelector,
  paymentFormReducerExports.stripeDataSelector
);

const formDataErrorsSelector = createSelector(formDataSelector, (formData) => {
  return formData
    .filter((field) => field.get('isDirty'))
    .map((field, fieldId) =>
      paymentFormValidator.validateField(fieldId, field.get('value'))
    )
    .filter(isString)
    .map((error, fieldId) => `${fieldIdToLabel.get(fieldId)}: ${error}`);
});

const stripeDataErrorsSelector = createSelector(
  stripeDataSelector,
  (stripeData) =>
    stripeData
      .map((field) => field.get('error'))
      .filter(isString)
      .map((error, fieldId) => `${fieldIdToLabel.get(fieldId)}: ${error}`)
);

const formErrorsSelector = createSelector(
  formDataErrorsSelector,
  stripeDataErrorsSelector,
  (formDataErrors, stripeDataErrors) => formDataErrors.merge(stripeDataErrors)
);

export const fieldErrorSelector = (state, ...fieldIds) => {
  const formErrors = formErrorsSelector(state);

  return Immutable.fromJS(fieldIds)
    .map((fieldId) => formErrors.get(fieldId))
    .filter(isString);
};

export const hasFieldErroredSelector = (state, fieldId) =>
  formErrorsSelector(state).has(fieldId);

const areStripeFieldsIncompleteSelector = createSelector(
  stripeDataSelector,
  (stripeData) => stripeData.some((field) => field.get('complete') === false)
);

const isFormDataInvalidSelector = createSelector(
  formDataSelector,
  (formData) => {
    return formData
      .map((field, fieldId) =>
        paymentFormValidator.validateField(fieldId, field.get('value'))
      )
      .some(isString);
  }
);

export const isPaymentFormIncompleteSelector = createSelector(
  areStripeFieldsIncompleteSelector,
  isFormDataInvalidSelector,
  (...statuses) => some(statuses)
);

export const storeInformationLogoUrlSelector = createSelector(
  storeInformationSelector,
  storeInformationReducerExports.logoUrlSelector
);

export const storeInformationNameSelector = createSelector(
  storeInformationSelector,
  storeInformationReducerExports.nameSelector
);

const storeInformationStreetAddressSelector = createSelector(
  storeInformationSelector,
  storeInformationReducerExports.streetAddressSelector
);

const storeInformationStreetAddress2Selector = createSelector(
  storeInformationSelector,
  storeInformationReducerExports.streetAddress2Selector
);

const storeInformationCitySelector = createSelector(
  storeInformationSelector,
  storeInformationReducerExports.citySelector
);

const storeInformationStateSelector = createSelector(
  storeInformationSelector,
  storeInformationReducerExports.stateSelector
);

const storeInformationZipcodeSelector = createSelector(
  storeInformationSelector,
  storeInformationReducerExports.zipcodeSelector
);

const hasTipsEnabledSelector = createSelector(
  storeInformationSelector,
  storeInformationReducerExports.tipsEnabledSelector
);

export const suggestedTipsSelector = createSelector(
  storeInformationSelector,
  storeInformationReducerExports.suggestedTipsSelector
);

export const storeInformationAddressSelector = createSelector(
  storeInformationStateSelector,
  storeInformationZipcodeSelector,
  storeInformationStreetAddressSelector,
  storeInformationStreetAddress2Selector,
  storeInformationCitySelector,
  (state = '', zipcode = '', ...addressParts) => {
    const fullAddressParts = Immutable.fromJS([
      ...addressParts,
      trim(`${state} ${zipcode}`),
    ]);

    const addressString = fullAddressParts
      .filter((addressPart) => !isEmpty(addressPart))
      .join(', ');

    return !isEmpty(addressString) ? addressString : undefined;
  }
);

export const fetchPaymentRequestSuccessPropertySelector = createSelector(
  paymentRequestChannelSelector,
  (channel) => ({ channel })
);

export const hasFeatureSelector = (state, feature) => {
  const features = featuresSelector(state);
  return features.get(feature, false);
};

export const showGratuityCardSelector = createSelector(
  isPaymentRequestEditableSelector,
  hasPayByLinkChannelSelector,
  hasTipsEnabledSelector,
  hasGratuityPresentSelector,
  (
    isPaymentRequestEditable,
    hasPayByLinkChannel,
    hasTipsEnabled,
    hasGratuityPresent
  ) =>
    isPaymentRequestEditable &&
    hasPayByLinkChannel &&
    hasTipsEnabled &&
    hasGratuityPresent === false
);

export const showGratuityLineSelector = createSelector(
  hasPayByLinkChannelSelector,
  hasGratuityPresentSelector,
  hasTipsEnabledSelector,
  (hasPayByLinkChannel, hasGratuityPresent, hasTipsEnabled) =>
    hasPayByLinkChannel && (hasGratuityPresent || hasTipsEnabled)
);

export const isGratuityRemovableSelector = createSelector(
  hasGratuityPresentSelector,
  isPaymentRequestEditableSelector,
  isPaymentRequestPatchGratuityPendingSelector,
  (hasGratuityPresent, isPaymentRequestEditable, isPatchGratuityPending) =>
    hasGratuityPresent && isPaymentRequestEditable && !isPatchGratuityPending
);

export const arePaymentRequestUpdatesBlockedSelector = createSelector(
  isPaymentFormProcessingSelector,
  isPaymentRequestPatchGratuityPendingSelector,
  (...statuses) => some(statuses)
);
