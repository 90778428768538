import axios from 'axios';
import result from 'lodash/result';
import * as paymentRequestReducerExports from 'reducers/payment-request';
import { formatPaymentRequestPayload } from './formatters';
import { apiGatewayUrlSelector } from 'selectors';

const fetchPaymentRequest = (paymentRequestUuid) => {
  return async (dispatch, getState) => {
    dispatch(paymentRequestReducerExports.fetchPaymentRequestPending());

    const apiGatewayUrl = apiGatewayUrlSelector(getState());
    let response;

    try {
      response = await axios({
        method: 'get',
        url: `${apiGatewayUrl}/online-payments/v1/payment_requests/${paymentRequestUuid}`,
      });
    } catch (e) {
      const statusCode = result(e, 'response.status', 404);
      return dispatch(
        paymentRequestReducerExports.fetchPaymentRequestFailure(statusCode)
      );
    }

    const formattedData = formatPaymentRequestPayload(response.data);

    dispatch(
      paymentRequestReducerExports.fetchPaymentRequestSuccess(formattedData)
    );
  };
};

export default fetchPaymentRequest;
