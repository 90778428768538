import get from 'lodash/get';
import * as paymentFormReducerExports from 'reducers/payment-form';
import { paymentIntentClientSecretSelector } from 'selectors';
import * as paymentIntentStates from 'constants/payment-intent-states';
import * as paymentRequestReducerExports from 'reducers/payment-request';

const confirmWalletPayment = ({ stripe, event }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const clientSecret = paymentIntentClientSecretSelector(state);

    try {
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: event.paymentMethod.id,
      });

      if (result.error) throw result;

      event.complete('success');

      dispatch(paymentFormReducerExports.paymentFormProcessingSuccess());
    } catch (e) {
      event.complete('fail');

      const paymentIntentStatus = get(e, 'error.payment_intent.status');

      if (paymentIntentStatus === paymentIntentStates.SUCCEEDED) {
        dispatch(paymentFormReducerExports.paymentFormProcessingSuccess());
        return;
      }

      if (paymentIntentStatus === paymentIntentStates.CANCELED) {
        dispatch(paymentRequestReducerExports.paymentRequestMarkAsCanceled());
        return;
      }

      const errorMessage = get(
        e,
        'error.message',
        'An error has occurred. Please try again.'
      );

      dispatch(
        paymentFormReducerExports.paymentFormProcessingFailure(errorMessage)
      );
    }
  };
};

export default confirmWalletPayment;
