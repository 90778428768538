import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import './stylesheets/field-errors.scss';

const namespace = 'field-errors';

const FieldErrors = ({ className, errors }) => {
  if (errors.isEmpty()) return null;

  const containerClasses = cx({
    [namespace]: true,
    [className]: !isEmpty(className),
  });

  return (
    <ul className={containerClasses}>
      {errors.map((error, index) => (
        <li className={`${namespace}__error`} key={`field-error-${index}`}>
          {error}
        </li>
      ))}
    </ul>
  );
};

FieldErrors.propTypes = {
  className: PropTypes.string,
  errors: ImmutablePropTypes.list.isRequired,
};

export default FieldErrors;
