import React from 'react';
import TextCard from 'components/text-card';

const GenericErrorCard = () => {
  return (
    <TextCard
      title="An error has occurred."
      subtitle="We've encountered an error when trying to load this order. Please try again later."
    />
  );
};

export default GenericErrorCard;
