import Immutable from 'immutable';
import isEmpty from 'lodash/isEmpty';
import * as submissionStates from 'constants/submission-states';
import { PATCH_GRATUITY_PENDING } from 'reducers/payment-request';

export const PAYMENT_FORM_SET_FIELD_VALUE = 'PAYMENT_FORM_SET_FIELD_VALUE';

export const PAYMENT_FORM_FIELD_BLURRED = 'PAYMENT_FORM_FIELD_BLURRED';

export const PAYMENT_FORM_SET_STRIPE_FIELD = 'PAYMENT_FORM_SET_STRIPE_FIELD';

export const PAYMENT_FORM_PROCESSING_PENDING =
  'PAYMENT_FORM_PROCESSING_PENDING';

export const PAYMENT_FORM_PROCESSING_SUCCESS =
  'PAYMENT_FORM_PROCESSING_SUCCESS';

export const PAYMENT_FORM_PROCESSING_FAILURE =
  'PAYMENT_FORM_PROCESSING_FAILURE';

export const PAYMENT_FORM_RESET_PROCESSING = 'PAYMENT_FORM_RESET_PROCESSING';

export const paymentFormProcessingPending = () => ({
  type: PAYMENT_FORM_PROCESSING_PENDING,
});

export const paymentFormProcessingSuccess = () => ({
  type: PAYMENT_FORM_PROCESSING_SUCCESS,
});

export const paymentFormProcessingFailure = (errorMessage) => ({
  type: PAYMENT_FORM_PROCESSING_FAILURE,
  errorMessage,
});

export const paymentFormFieldBlurred = (id) => ({
  type: PAYMENT_FORM_FIELD_BLURRED,
  id,
});

export const paymentFormSetFieldValue = ({ id, value }) => ({
  type: PAYMENT_FORM_SET_FIELD_VALUE,
  id,
  value,
});

export const paymentFormSetStripeField = ({ id, complete, error }) => ({
  type: PAYMENT_FORM_SET_STRIPE_FIELD,
  id,
  complete,
  error,
});

export const paymentFormResetProcessing = () => ({
  type: PAYMENT_FORM_RESET_PROCESSING,
});

const defaultState = Immutable.fromJS({
  processingStatus: submissionStates.INITIAL,
  processingErrorMessage: undefined,
  formData: {
    cardholderName: {
      isDirty: false,
      value: '',
    },
    billingZipCode: {
      isDirty: false,
      value: '',
    },
  },
  stripeData: {
    cardNumber: {
      complete: false,
      error: undefined,
    },
    cvv: {
      complete: false,
      error: undefined,
    },
    expiry: {
      complete: false,
      error: undefined,
    },
  },
});

export const processingStatusSelector = (state) =>
  state.get('processingStatus');

export const processingErrorMessageSelector = (state) =>
  state.get('processingErrorMessage');

export const formDataSelector = (state) => state.get('formData');

export const stripeDataSelector = (state) => state.get('stripeData');

export const formFieldValueSelector = (state, fieldId) =>
  formDataSelector(state).getIn([fieldId, 'value']);

export default function (state = defaultState, action) {
  switch (action.type) {
    case PAYMENT_FORM_SET_FIELD_VALUE:
      return state.setIn(
        ['formData', action.id],
        Immutable.Map({ isDirty: false, value: action.value })
      );
    case PAYMENT_FORM_FIELD_BLURRED:
      const fieldValue = formFieldValueSelector(state, action.id);
      return state.setIn(
        ['formData', action.id, 'isDirty'],
        !isEmpty(fieldValue)
      );
    case PAYMENT_FORM_SET_STRIPE_FIELD:
      return state.setIn(
        ['stripeData', action.id],
        Immutable.Map({
          complete: action.complete,
          error: action.error,
        })
      );
    case PAYMENT_FORM_PROCESSING_PENDING:
      return state
        .set('processingStatus', submissionStates.PENDING)
        .set('processingErrorMessage', undefined);
    case PAYMENT_FORM_PROCESSING_FAILURE:
      return state
        .set('processingStatus', submissionStates.FAILURE)
        .set('processingErrorMessage', action.errorMessage);
    case PATCH_GRATUITY_PENDING:
    case PAYMENT_FORM_RESET_PROCESSING:
      return state
        .set('processingStatus', submissionStates.INITIAL)
        .set('processingErrorMessage', undefined);
    default:
      return state;
  }
}
