import colors from '@shopkeep/colors/dist/colors';

const createStripeOptions = (
  placeholderColor = colors.shopkeepSteelLight2
) => ({
  classes: {
    invalid: 'payment-card__input--invalid',
  },
  style: {
    base: {
      color: colors.shopkeepSteel,
      fontFamily: 'Open Sans, Arial, serif',
      fontSize: '14px',
      fontWeight: '300',
      '::placeholder': {
        color: placeholderColor,
      },
    },
    invalid: {
      color: colors.shopkeepSteel,
    },
  },
});

export const optionsWithPlaceholder = createStripeOptions();

export const optionsWithoutPlaceholder = createStripeOptions('transparent');
