import { snowplowAppIdSelector, snowplowCollectorUrlSelector } from 'selectors';

const fetchSnowplow = () => {
  return (dispatch, getState) => {
    if (process.env.NODE_ENV !== 'production') return;

    const storeState = getState();
    const snowplowAppId = snowplowAppIdSelector(storeState);
    const snowplowCollectorUrl = snowplowCollectorUrlSelector(storeState);

    (function (p, l, o, w, i, n, g) {
      if (!p[i]) {
        p.GlobalSnowplowNamespace = p.GlobalSnowplowNamespace || [];
        p.GlobalSnowplowNamespace.push(i);
        p[i] = function () {
          (p[i].q = p[i].q || []).push(arguments);
        };
        p[i].q = p[i].q || [];
        n = l.createElement(o);
        g = l.getElementsByTagName(o)[0];
        n.async = 1;
        n.src = w;
        g.parentNode.insertBefore(n, g);
      }
    })(
      window,
      document,
      'script',
      '//d1fc8wv8zag5ca.cloudfront.net/2.9.0/sp.js',
      'snowplow'
    );

    window.snowplow('newTracker', 'javascript', snowplowCollectorUrl, {
      appId: snowplowAppId,
      cookieDomain: null,
    });

    window.snowplow('trackPageView');
  };
};

export default fetchSnowplow;
