import React from 'react';
import PropTypes from 'prop-types';
import './stylesheets/google-pay-button.scss';

const namespace = 'google-pay-button';

const GooglePayButton = ({ onClick }) => {
  return (
    <button
      aria-label="Buy with Google Pay"
      onClick={onClick}
      className={namespace}
    />
  );
};

GooglePayButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default GooglePayButton;
