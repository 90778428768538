import axios from 'axios';
import result from 'lodash/result';
import {
  patchGratuityPending,
  patchGratuityFailure,
  patchGratuitySuccess,
} from 'reducers/payment-request';
import { apiGatewayUrlSelector } from 'selectors';
import { formatPaymentRequestPayload } from './formatters';

const patchPaymentRequest = ({ paymentRequestId, gratuity }) => {
  return async (dispatch, getState) => {
    dispatch(patchGratuityPending());

    const apiGatewayUrl = apiGatewayUrlSelector(getState());
    let response;

    try {
      response = await axios({
        method: 'patch',
        url: `${apiGatewayUrl}/online-payments/v1/payment_requests/${paymentRequestId}/gratuity`,
        data: { gratuity },
      });
    } catch (e) {
      const errorMessage = result(
        e,
        'response.data.error',
        'An error has occurred. Please try again.'
      );

      return dispatch(patchGratuityFailure(errorMessage));
    }

    const formattedData = formatPaymentRequestPayload(response.data);

    dispatch(patchGratuitySuccess(formattedData));
  };
};

export default patchPaymentRequest;
