import axios from 'axios';
import Immutable from 'immutable';
import * as configReducerExports from 'reducers/config';

const fetchConfig = () => {
  return async (dispatch) => {
    dispatch(configReducerExports.fetchConfigPending());

    try {
      const response = await axios.get('/config.json');
      const parsedData = Immutable.fromJS(response.data);
      return dispatch(configReducerExports.fetchConfigSuccess(parsedData));
    } catch (e) {
      return dispatch(configReducerExports.fetchConfigFailure());
    }
  };
};

export default fetchConfig;
