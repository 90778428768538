import Immutable from 'immutable';
import { createSelector } from 'reselect';
import * as submissionStates from 'constants/submission-states';

export const FETCH_CONFIG_PENDING = 'FETCH_CONFIG_PENDING';
export const FETCH_CONFIG_FAILURE = 'FETCH_CONFIG_FAILURE';
export const FETCH_CONFIG_SUCCESS = 'FETCH_CONFIG_SUCCESS';

export const FETCH_PAYMENT_LIBRARY_PENDING = 'FETCH_PAYMENT_LIBRARY_PENDING';
export const FETCH_PAYMENT_LIBRARY_FAILURE = 'FETCH_PAYMENT_LIBRARY_FAILURE';
export const FETCH_PAYMENT_LIBRARY_SUCCESS = 'FETCH_PAYMENT_LIBRARY_SUCCESS';

export const fetchConfigPending = () => ({
  type: FETCH_CONFIG_PENDING,
});

export const fetchConfigFailure = () => ({
  type: FETCH_CONFIG_FAILURE,
});

export const fetchConfigSuccess = (data) => ({
  type: FETCH_CONFIG_SUCCESS,
  data,
});

export const fetchPaymentLibraryPending = () => ({
  type: FETCH_PAYMENT_LIBRARY_PENDING,
});

export const fetchPaymentLibraryFailure = () => ({
  type: FETCH_PAYMENT_LIBRARY_FAILURE,
});

export const fetchPaymentLibrarySuccess = () => ({
  type: FETCH_PAYMENT_LIBRARY_SUCCESS,
});

const defaultState = Immutable.fromJS({
  loadingState: submissionStates.INITIAL,
  paymentLibraryLoadingState: submissionStates.INITIAL,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_CONFIG_PENDING:
      return state.delete('data').set('loadingState', submissionStates.PENDING);
    case FETCH_CONFIG_FAILURE:
      return state.delete('data').set('loadingState', submissionStates.FAILURE);
    case FETCH_CONFIG_SUCCESS:
      return state.merge({
        data: action.data,
        loadingState: submissionStates.SUCCESS,
      });
    case FETCH_PAYMENT_LIBRARY_PENDING:
      return state.set('paymentLibraryLoadingState', submissionStates.PENDING);
    case FETCH_PAYMENT_LIBRARY_FAILURE:
      return state.set('paymentLibraryLoadingState', submissionStates.FAILURE);
    case FETCH_PAYMENT_LIBRARY_SUCCESS:
      return state.set('paymentLibraryLoadingState', submissionStates.SUCCESS);
    default:
      return state;
  }
};

const dataSelector = (state) => state.get('data', Immutable.Map());

export const loadingStateSelector = (state) => state.get('loadingState');

export const paymentLibraryLoadingStateSelector = (state) =>
  state.get('paymentLibraryLoadingState');

export const apiGatewayUrlSelector = createSelector(dataSelector, (data) =>
  data.get('apiGatewayUrl')
);

export const snowplowAppIdSelector = createSelector(dataSelector, (data) =>
  data.get('snowplowAppId')
);

export const snowplowCollectorUrlSelector = createSelector(
  dataSelector,
  (data) => data.get('snowplowCollectorUrl')
);

export const stripeApiPublishableKeySelector = createSelector(
  dataSelector,
  (data) => data.get('stripeApiPublishableKey')
);
