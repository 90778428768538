import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button';
import Modal from 'components/modal';
import './stylesheets/confirm-modal.scss';

const namespace = 'confirm-modal';

const ConfirmModal = ({ isOpen, onClose, onConfirm, threshold }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className={`${namespace}__wrapper`}>
        <h1 className={`${namespace}__title`}>Confirm Gratuity Amount</h1>
        <p className={`${namespace}__body`}>
          Gratuity amount entered is greater than {threshold}% of the
          transaction amount. Are you sure?
        </p>
        <Button className={`${namespace}__button`} onClick={onConfirm}>
          Confirm
        </Button>
        <Button
          className={`${namespace}__button`}
          onClick={onClose}
          theme="alt"
        >
          Re-enter
        </Button>
      </div>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  threshold: PropTypes.number.isRequired,
};

export default ConfirmModal;
