import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import FormattedNumber from 'components/formatted-number';
import './stylesheets/total.scss';

const namespace = 'info-card-total';

const InfoCardTotal = ({
  afterValue,
  className,
  label,
  onValueClick,
  value,
  mode = 'condensed',
}) => {
  const containerClasses = cx({
    [className]: !isEmpty(className),
    [namespace]: true,
    [`${namespace}--${mode}`]: true,
  });

  const valueContents = (
    <React.Fragment>
      <FormattedNumber className={`${namespace}__text`} value={value} />
      {afterValue}
    </React.Fragment>
  );

  return (
    <div className={containerClasses}>
      <span className={`${namespace}__text`}>{label}</span>

      <div className={`${namespace}__value-wrapper`}>
        {isFunction(onValueClick) ? (
          <button className={`${namespace}__button`} onClick={onValueClick}>
            {valueContents}
          </button>
        ) : (
          valueContents
        )}
      </div>
    </div>
  );
};

InfoCardTotal.propTypes = {
  afterValue: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onValueClick: PropTypes.func,
  value: PropTypes.number.isRequired,
  mode: PropTypes.oneOf(['condensed', 'standalone']),
};

export default InfoCardTotal;
