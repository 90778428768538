import I18n from 'i18n-js';
import * as i18nConstants from 'constants/i18n';

const formatNumber = function (
  value,
  { precision = i18nConstants.defaultCurrencyPrecision } = {}
) {
  return I18n.toNumber(value, {
    precision: precision,
  });
};

export default formatNumber;
