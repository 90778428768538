import Immutable from 'immutable';
import { createSelector } from 'reselect';
import {
  PAYMENT_FORM_PROCESSING_PENDING,
  PAYMENT_FORM_PROCESSING_SUCCESS,
} from 'reducers/payment-form';
import * as paymentRequestStatuses from 'constants/payment-request-statuses';
import * as submissionStates from 'constants/submission-states';

export const FETCH_PAYMENT_REQUEST_PENDING = 'FETCH_PAYMENT_REQUEST_PENDING';

export const FETCH_PAYMENT_REQUEST_FAILURE = 'FETCH_PAYMENT_REQUEST_FAILURE';

export const FETCH_PAYMENT_REQUEST_SUCCESS = 'FETCH_PAYMENT_REQUEST_SUCCESS';

export const PATCH_GRATUITY_PENDING = 'PATCH_GRATUITY_PENDING';

export const PATCH_GRATUITY_FAILURE = 'PATCH_GRATUITY_FAILURE';

export const PATCH_GRATUITY_SUCCESS = 'PATCH_GRATUITY_SUCCESS';

export const PATCH_GRATUITY_RESET = 'PATCH_GRATUITY_RESET';

export const PAYMENT_REQUEST_MARK_AS_CANCELED =
  'PAYMENT_REQUEST_MARK_AS_CANCELED';

export const fetchPaymentRequestPending = () => ({
  type: FETCH_PAYMENT_REQUEST_PENDING,
});

export const fetchPaymentRequestFailure = (statusCode) => ({
  type: FETCH_PAYMENT_REQUEST_FAILURE,
  statusCode,
});

export const fetchPaymentRequestSuccess = (data) => ({
  type: FETCH_PAYMENT_REQUEST_SUCCESS,
  data,
});

export const patchGratuityPending = () => ({
  type: PATCH_GRATUITY_PENDING,
});

export const patchGratuityFailure = (errorMessage) => ({
  type: PATCH_GRATUITY_FAILURE,
  errorMessage,
});

export const patchGratuitySuccess = (data) => ({
  type: PATCH_GRATUITY_SUCCESS,
  data,
});

export const paymentRequestMarkAsCanceled = () => ({
  type: PAYMENT_REQUEST_MARK_AS_CANCELED,
});

export const patchGratuityReset = () => ({
  type: PATCH_GRATUITY_RESET,
});

const defaultState = Immutable.fromJS({
  loadingState: submissionStates.INITIAL,
  patchGratuityRequestState: submissionStates.INITIAL,
  patchGratuityRequestErrorMessage: undefined,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_PAYMENT_REQUEST_PENDING:
      return state
        .delete('data')
        .delete('loadingStatusErrorCode')
        .set('loadingState', submissionStates.PENDING);
    case FETCH_PAYMENT_REQUEST_FAILURE:
      return state
        .delete('data')
        .set('loadingStatusErrorCode', action.statusCode)
        .set('loadingState', submissionStates.FAILURE);
    case FETCH_PAYMENT_REQUEST_SUCCESS:
      return state
        .set('data', action.data)
        .delete('loadingStatusErrorCode')
        .set('loadingState', submissionStates.SUCCESS);
    case PATCH_GRATUITY_RESET:
    case PAYMENT_FORM_PROCESSING_PENDING:
      return state
        .set('patchGratuityRequestState', submissionStates.INITIAL)
        .set('patchGratuityRequestErrorMessage', undefined);
    case PAYMENT_FORM_PROCESSING_SUCCESS:
      return state.setIn(['data', 'status'], paymentRequestStatuses.SUCCEEDED);
    case PAYMENT_REQUEST_MARK_AS_CANCELED:
      return state.setIn(['data', 'status'], paymentRequestStatuses.CANCELED);
    case PATCH_GRATUITY_PENDING:
      return state
        .set('patchGratuityRequestState', submissionStates.PENDING)
        .set('patchGratuityRequestErrorMessage', undefined);
    case PATCH_GRATUITY_FAILURE:
      return state
        .set('patchGratuityRequestState', submissionStates.FAILURE)
        .set('patchGratuityRequestErrorMessage', action.errorMessage);
    case PATCH_GRATUITY_SUCCESS:
      return state
        .set('patchGratuityRequestState', submissionStates.SUCCESS)
        .set('data', action.data);
    default:
      return state;
  }
};

const dataSelector = (state) => state.get('data', Immutable.Map());

export const loadingStateSelector = (state) => state.get('loadingState');

export const patchGratuityRequestStateSelector = (state) =>
  state.get('patchGratuityRequestState');

export const loadingStatusErrorCodeSelector = (state) =>
  state.get('loadingStatusErrorCode');

export const patchGratuityRequestErrorMessageSelector = (state) =>
  state.get('patchGratuityRequestErrorMessage');

export const paymentRequestIdSelector = createSelector(dataSelector, (data) =>
  data.get('id')
);

export const paymentIntentClientSecretSelector = createSelector(
  dataSelector,
  (data) => data.get('paymentIntentClientSecret')
);

export const statusSelector = createSelector(dataSelector, (data) =>
  data.get('status')
);

export const storeUuidSelector = createSelector(dataSelector, (data) =>
  data.get('storeUuid')
);

export const subtotalSelector = createSelector(dataSelector, (data) =>
  data.get('subtotal')
);

export const taxSelector = createSelector(dataSelector, (data) =>
  data.get('tax')
);

export const gratuitySelector = createSelector(dataSelector, (data) =>
  data.get('gratuity')
);

export const totalSelector = createSelector(dataSelector, (data) =>
  data.get('total')
);

export const channelSelector = createSelector(dataSelector, (data) =>
  data.get('channel')
);

export const currencySelector = createSelector(dataSelector, (data) =>
  data.get('currency')
);
