import Immutable from 'immutable';
import { createSelector } from 'reselect';
import * as submissionStates from 'constants/submission-states';

export const FETCH_STORE_INFORMATION_PENDING =
  'FETCH_STORE_INFORMATION_PENDING';

export const FETCH_STORE_INFORMATION_FAILURE =
  'FETCH_STORE_INFORMATION_FAILURE';

export const FETCH_STORE_INFORMATION_SUCCESS =
  'FETCH_STORE_INFORMATION_SUCCESS';

export const fetchStoreInformationPending = () => ({
  type: FETCH_STORE_INFORMATION_PENDING,
});

export const fetchStoreInformationFailure = () => ({
  type: FETCH_STORE_INFORMATION_FAILURE,
});

export const fetchStoreInformationSuccess = (data) => ({
  type: FETCH_STORE_INFORMATION_SUCCESS,
  data,
});

const defaultState = Immutable.fromJS({
  loadingState: submissionStates.INITIAL,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_STORE_INFORMATION_PENDING:
      return state.delete('data').set('loadingState', submissionStates.PENDING);
    case FETCH_STORE_INFORMATION_FAILURE:
      return state.delete('data').set('loadingState', submissionStates.FAILURE);
    case FETCH_STORE_INFORMATION_SUCCESS:
      return state.merge({
        data: action.data,
        loadingState: submissionStates.SUCCESS,
      });
    default:
      return state;
  }
};

const dataSelector = (state) => state.get('data', Immutable.Map());

export const loadingStateSelector = (state) => state.get('loadingState');

export const logoUrlSelector = createSelector(dataSelector, (data) =>
  data.get('logoUrl')
);

export const nameSelector = createSelector(dataSelector, (data) =>
  data.get('name')
);

export const streetAddressSelector = createSelector(dataSelector, (data) =>
  data.get('streetAddress')
);

export const streetAddress2Selector = createSelector(dataSelector, (data) =>
  data.get('streetAddress2')
);

export const citySelector = createSelector(dataSelector, (data) =>
  data.get('city')
);

export const stateSelector = createSelector(dataSelector, (data) =>
  data.get('state')
);

export const zipcodeSelector = createSelector(dataSelector, (data) =>
  data.get('zipcode')
);

export const tipsEnabledSelector = createSelector(dataSelector, (data) =>
  data.getIn(['tipSettings', 'enabled'], false)
);

export const suggestedTipsSelector = createSelector(dataSelector, (data) =>
  data.getIn(['tipSettings', 'suggestedTips'])
);
