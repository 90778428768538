import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import './stylesheets/index.scss';

const namespace = 'card';

const Card = ({ children, className, headerText }) => {
  const containerClasses = cx({
    [namespace]: true,
    [className]: !isEmpty(className),
  });

  return (
    <div className={containerClasses}>
      {!isEmpty(headerText) && (
        <div className={`${namespace}__header-container`}>
          <h2 className={`${namespace}__header`}>{headerText}</h2>
        </div>
      )}
      <div className={`${namespace}__content-container`}>{children}</div>
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  headerText: PropTypes.string,
};

export default Card;
