import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isGratuityRemovableSelector,
  isPaymentRequestPatchGratuityPendingSelector,
  paymentRequestIdSelector,
  paymentRequestGratuityAsDecimalSelector,
} from 'selectors';
import Spinner from 'components/spinner';
import InfoCardTotal from './total';
import patchGratuity from 'api/patch-gratuity';
import './stylesheets/gratuity-line.scss';

const namespace = 'gratuity-line';

const GratuityLine = () => {
  const dispatch = useDispatch();
  const gratuity = useSelector(paymentRequestGratuityAsDecimalSelector);
  const isRequestPending = useSelector(
    isPaymentRequestPatchGratuityPendingSelector
  );
  const isGratuityRemovable = useSelector(isGratuityRemovableSelector);
  const paymentRequestId = useSelector(paymentRequestIdSelector);

  const onValueClick = useCallback(() => {
    dispatch(
      patchGratuity({
        paymentRequestId,
        gratuity: 0,
      })
    );
  }, [dispatch, paymentRequestId]);

  return (
    <InfoCardTotal
      className="info-card__gratuity"
      label="Gratuity"
      value={gratuity}
      onValueClick={isGratuityRemovable ? onValueClick : undefined}
      afterValue={
        <React.Fragment>
          {isRequestPending && (
            <Spinner className={`${namespace}__spinner`} mode="dark" />
          )}

          {isGratuityRemovable && (
            <div className={`${namespace}__pencil`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 14">
                <path
                  fill="currentColor"
                  d="M83.038 16.372l-.381.657-1.946-1.12.381-.656c.207-.358.612-.52.904-.35l.892.513c.291.168.359.597.15.956zm-4.814 8.304l-1.947-1.12 4.012-6.919 1.945 1.12-4.01 6.919zm-2.145-.264l1.501.862-1.628 1.084.127-1.946zm7.23-9.723l-.892-.513c-.69-.395-1.613-.103-2.056.657l-5.025 8.666L75 28l3.612-2.403 5.156-8.805c.44-.76.234-1.703-.458-2.103z"
                  transform="translate(-771 -284) translate(480 20) translate(10 202) translate(10) translate(0 48) translate(196)"
                />
              </svg>
            </div>
          )}
        </React.Fragment>
      }
    />
  );
};

export default GratuityLine;
